<!-- Hero Start -->
<section class="bg-half-100 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> {{ (lottery) ? lottery.name : 'No se ha encontrado la rifa'}} </h4>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <ngb-carousel [showNavigationArrows]="true" *ngIf="images.length > 0; else noImageTemplate;" class="lottery-carousel">
                    <ng-template ngbSlide *ngFor="let image of images">
                        <div class="picsum-img-wrapper">
                            <img [src]="image.replace('./','https://www.ganatetuauto.com/')" alt="Random first slide" class="img-fluid lottery-image" />
                        </div>
                    </ng-template>
                </ngb-carousel>
                <!-- <img [src]="lottery.image.replace('./','https://www.ganatetuauto.com/')" alt="Foto" *ngIf="lottery && lottery.image && images.length > 0; else noImageTemplate;" class="img-fluid lottery-image"> -->
                <ng-template #noImageTemplate>
                    <img [src]="'assets/images/no-image.png'" alt="" class="img-fluid">
                </ng-template>

            </div>
            <div class="col-md-8">
                <div class="row">
                    <div class="col-sm-3">
                        <h6 class="title">Tickets Vendidos</h6>
                    </div>
                    <div class="col-sm-9 progress-box mt-4">
                        <div class="progress">
                            <div class="progress-bar position-relative bg-primary" [style]="'width: '+lottery.percentageBought+'%;'">
                                <div class="progress-value d-block text-muted h6">{{lottery.percentageBought}}%</div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <!-- <div class="progress-box mt-4">
                    <h2 class="title text-muted">Boletos adquiridos</h2>
                    <div class="progress">
                        <div class="progress-bar position-relative bg-primary" [style]="'width: '+lottery.percentageBought+'%;'">
                            <div class="progress-value d-block text-muted h6">{{lottery.percentageBought}}%</div>
                        </div>
                    </div>
                </div> -->
                <h2 class="text-primary">Premios</h2>
                <ul>
                    <li *ngFor="let reward of rewards; index as i">
                        <h5>{{reward.label}}: {{reward.value}}</h5>
                    </li>
                </ul>
                <hr>
                <h2 class="text-primary">Detalles</h2>
                <p><b>Fecha del Sorteo:</b> {{lottery?.datetime | date: 'dd/MM/yyyy'}}</p>
                <div [innerHTML]="formattedText"></div>
                <br>
                <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#paymentForm" *ngIf="lottery?.status == 1 && lottery.percentageBought < 100"><button class="btn btn-primary">Comprar tickets</button></a>
            </div>
        </div>
    </div>
</section>

<!-- Modal Content Start -->
<div class="modal fade" id="paymentForm" tabindex="-1" aria-labelledby="paymentForm-title" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" [ngClass]="(stages[stageIndex] == 'paymentForm') ? 'modal-lg' : ''">
        <div class="modal-content rounded shadow border-0">
            <div class="modal-header border-bottom">
                <h5 class="modal-title" id="paymentForm-title">{{lottery?.name}}</h5>
                <button type="button" (click)="resetForm()" class="btn btn-icon btn-close" data-bs-dismiss="modal" id="close-modal"><i class="uil uil-times fs-4 text-dark"></i></button>
            </div>
            <div class="modal-body" *ngIf="stages[stageIndex] == 'ticketsAmount'">
                <p style="text-align: center;">Ingrese la cantidad de tickets a comprar</p>
                <div class="d-flex" style="justify-content: center;">
                    <button type="button" (click)="decreaseTickets()" class="btn btn-warning col-sm-1 px-3">-</button>
                    <div class="form-icon position-relative col-sm-4 mx-1">
                        <i-feather name="tag" class="fea icon-sm icons"></i-feather>
                        <input name="ticketsAmount" id="ticketsAmount" type="number" min="1" [(ngModel)]="ticketsAmount" class="form-control ps-5">
                    </div>
                    <button type="button" (click)="increaseTickets()" class="btn btn-success col-sm-1 px-3">+</button>
                </div>
                <p style="text-align: center;">Cantidad Mínima de boletos a comprar: {{lottery?.minimumTickets}}</p>
                <p style="text-align: center; color:red;" *ngIf="ticketsAmount > ((lottery.ticketsRange + 1) - lottery.boughtTickets)|| ticketsAmount < lottery.minimumTickets">Tickets disponibles: {{(lottery.ticketsRange + 1) - lottery.boughtTickets}}</p>
            </div>

            <div class="modal-body" *ngIf="stages[stageIndex] == 'pricingDetails'">
                <p style="text-align: center;">Fecha del Sorteo: {{lottery?.datetime | date: 'dd/MM/yyyy'}}</p>
                <p style="text-align: center;">Total de boletos a comprar: {{ticketsAmount}}</p>
                <p style="text-align: center;">Precio unitario del boleto (USD): {{lottery?.price}}</p>
                <p style="text-align: center;">Tasa BCV del día: {{bcvPrice}}</p>
                <hr>
                <p style="text-align: center;"> Monto total a cancelar (USD): {{lottery?.price * ticketsAmount}}</p>
            </div>

            <div class="modal-body" *ngIf="stages[stageIndex] == 'paymentMethod'">
                <div class="d-flex justify-content-center">
                    <div class="payment-method" (click)="advanceStage()" style="cursor: pointer;">
                        <p style="text-align: center;">Pago Móvil</p>
                        <img src="assets/images/pago_movil.png" alt="Pago Móvil" class="img-fluid" style="height: 50px;">
                    </div>
                </div>
            </div>

            <div class="modal-body" *ngIf="stages[stageIndex] == 'paymentForm'">
                <p style="text-align: center;">Por favor, realice el pago móvil a la siguiente cuenta. Luego, llene el formulario con sus datos del pago realizado a continuación:</p>
                <h6 class="text-primary">Datos del Pago Móvil</h6>
                <div class="row">
                    <div class="col-md-6">
                        <ul>
                            <li><b>Teléfono</b>: {{mobilePaymentPhoneNumber}}</li>
                            <li><b>C.I/RIF</b>: {{mobilePaymentIdNumber}}</li>
                            <li><b>Banco Destino</b>: {{mobilePaymentBank}}</li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <ul>
                            <li><b>Tasa de Cambio: {{bcvPrice}}</b></li>
                            <li><b>Monto Total (Bs)</b>: {{bcvPrice * lottery?.price * ticketsAmount | number : '1.2-2'}}</li>
                        </ul>
                    </div>
                </div>

                <h6 class="text-primary">¡Procure realizar el pago con el monto exacto incluyendo los céntimos!</h6>
                <form [formGroup]="paymentForm" enctype="multipart/form-data">
                    <div class="row">
                        <!-- LEFT -->
                        <div class="col-md-6">
                            <div class="mb-3">
                                <!-- <label class="form-label">Nombre y Apellido <span
                                        class="text-danger">*</span></label> -->
                                <div class="form-icon position-relative">
                                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                    <input required name="fullname" id="fullname" type="text" class="form-control ps-5" placeholder="Nombre y Apellido:" formControlName="fullname" [ngClass]="(customStylesValidated && paymentForm.get('fullname').invalid) ? 'form-control-invalid' : ''">
                                </div>
                            </div>

                            <div class="mb-3">
                                <!-- <label class="form-label">Banco emisor <span
                                        class="text-danger">*</span></label> -->
                                <div class="form-icon position-relative">
                                    <i-feather name="dollar-sign" class="fea icon-sm icons"></i-feather>
                                    <input required name="issuer_bank" id="issuer_bank" type="text" class="form-control ps-5" placeholder="Banco emisor:" formControlName="issuer_bank" [ngClass]="(customStylesValidated && paymentForm.get('issuer_bank').invalid) ? 'form-control-invalid' : ''">
                                </div>
                            </div>

                            <div class="mb-3">
                                <!-- <label class="form-label">Número de Teléfono <span
                                        class="text-danger">*</span></label> -->
                                <div class="form-icon position-relative">
                                    <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                                    <input required name="phoneNumber" id="phoneNumber" type="text" class="form-control ps-5" placeholder="Número de teléfono:" formControlName="phoneNumber" [ngClass]="(customStylesValidated && paymentForm.get('phoneNumber').invalid) ? 'form-control-invalid' : ''">
                                </div>
                            </div>

                            <div class="mb-3">
                                <!-- <label class="form-label">Cédula de Identidad <span
                                        class="text-danger">*</span></label> -->
                                <div class="form-icon position-relative">
                                    <!-- <i-feather name="calendar" class="fea icon-sm icons"></i-feather> -->
                                    <i class="mdi mdi-card-account-details icons"> </i>
                                    <input required name="id_number" id="id_number" (input)="onInput($event)" type="text" class="form-control ps-5" placeholder="Cédula de Identidad:" formControlName="id_number" [ngClass]="(customStylesValidated && paymentForm.get('id_number').invalid) ? 'form-control-invalid' : ''">
                                </div>
                            </div>
                        </div>

                        <!-- RIGHT -->
                        <div class="col-md-6">
                            <div class="mb-3">
                                <div class="form-icon position-relative">
                                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                    <input required name="email" id="email" type="email" class="form-control ps-5" placeholder="Correo:" formControlName="email" [ngClass]="(customStylesValidated && paymentForm.get('email').invalid) ? 'form-control-invalid' : ''">
                                </div>
                            </div>
                            <p style="font-size: 14px;">Nota: Procure ingresar su correo correctamente</p>

                            <div class="mb-3">
                                <!-- <label class="form-label">Número de Referencia <span
                                        class="text-danger">*</span></label> -->
                                <div class="form-icon position-relative">
                                    <i-feather name="hash" class="fea icon-sm icons"></i-feather>
                                    <input required name="reference" id="reference" type="text" class="form-control ps-5" placeholder="Número de Referencia:" formControlName="reference" [ngClass]="(customStylesValidated && paymentForm.get('reference').invalid) ? 'form-control-invalid' : ''">
                                </div>
                            </div>

                            <div class="mb-3">
                                <!-- <label class="form-label">Fecha de Pago <span
                                        class="text-danger">*</span></label> -->
                                <div class="form-icon position-relative">
                                    <i-feather name="calendar" class="fea icon-sm icons"></i-feather>
                                    <input required type="text" class="form-control ps-5" name="date" id="date" formControlName="datetime" mwlFlatpickr [altInput]="true">
                                    <!-- <input required name="date" id="date" class="form-control ps-5" placeholder="Fecha de pago:"> -->
                                </div>
                            </div>

                            <div class="mb-3">
                                <!-- <label class="form-label">Cargar Comprobante <span
                                        class="text-danger">*</span></label> -->
                                <p style="font-size: 14px;">Nota: Adjuntar comprobante de pago</p>
                                <div class="form-icon position-relative">
                                    <input required name="attachment" id="attachment" type="file" class="form-control" placeholder="Adjuntar: " (change)="onFileChange($event)" [ngClass]="(customStylesValidated && paymentForm.get('reference').invalid) ? 'form-control-invalid' : ''">
                                </div>

                            </div>
                        </div>
                    </div>
                    <!--end row-->
                </form>
            </div>

            <div class="modal-body" *ngIf="stages[stageIndex] == 'resultPage'">
                <div class="d-flex justify-content-center" *ngIf="loading; else modalResultTemplate">
                    <div class="loading-animation" style="flex-direction: column; text-align: center;">
                        <p style="text-align: center;">Procesando tu información</p>
                        <i-feather name="refresh-cw" class="fea icon-md icons" [class.spin-animation]="true"></i-feather>
                    </div>
                </div>
            </div>

            <ng-template #modalResultTemplate>
                <div class="d-flex justify-content-center">
                    <div style="flex-direction: column; text-align: center;" *ngIf="requestResult; else failedResultTemplate">
                        <p style="text-align: center;">Gracias por tu compra. Dentro de las siguientes 24 a 48 horas, recibirás un correo referente a tu compra y estado del pago realizado.</p>
                        <p style="text-align: center"> NOTA: Si no encuentra el correo, recomendamos buscar en la bandeja de SPAM o Correo No deseado. También puede contactarnos vía Whatsapp.</p>
                        <p>
                            <i-feather name="check" class="fea icon-md icons"></i-feather>
                        </p>
                        <button data-bs-dismiss="modal" class="btn btn-primary" (click)="resetForm()">Cerrar</button>
                    </div>

                    <ng-template #failedResultTemplate>
                        <div style="flex-direction: column; text-align: center;">
                            <p style="text-align: center;">Hubo un problema. Por favor, intente de nuevo.</p>
                            <i-feather name="alert-circle" class="fea icon-md icons"></i-feather>
                        </div>
                    </ng-template>

                </div>
            </ng-template>


            <div class="modal-footer" *ngIf="stages[stageIndex] != 'resultPage'">
                <button type="button" (click)="resetForm()" class="btn btn-secondary" data-bs-dismiss="modal" *ngIf="stageIndex == 0 || stages[stageIndex] == 'resultPage'; else goBackButtonTemplate;">Cancelar</button>
                <ng-template #goBackButtonTemplate>
                    <button type="button" (click)="backStage()" class="btn btn-secondary">Volver</button>
                </ng-template>

                <button *ngIf="stages[stageIndex] != 'paymentMethod' && stages[stageIndex] != 'paymentForm'; else paymentMethodButtonTemplate" type="button" (click)="advanceStage()" class="btn btn-primary" [disabled]="ticketsAmount > ((lottery.ticketsRange + 1) - lottery.boughtTickets)|| ticketsAmount < lottery.minimumTickets">
                    Continuar
                </button>

                <ng-template #paymentMethodButtonTemplate>
                    <button *ngIf="stages[stageIndex] == 'paymentForm'; else resultButtonTemplate" type="button" (click)="submit()" class="btn btn-primary" [disabled]="paymentForm.invalid">
                        Enviar
                    </button>
                </ng-template>
                <ng-template #resultButtonTemplate>
                    <button *ngIf="stages[stageIndex] == 'resultPage'" data-bs-dismiss="modal" class="btn btn-primary" (click)="resetForm()">
                        Cerrar
                    </button>
                </ng-template>
            </div>
        </div>
    </div>
</div>
<!-- Modal Content End -->
<a href="https://wa.me/{{contactNumber}}" target="_blank" class="whatsapp-button">
    <img src="assets/images/whatsapp_icon.png" alt="Whatsapp">
</a>