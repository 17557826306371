import { Component, Input } from '@angular/core';
import { Lottery } from 'src/app/shared/models/lottery.model';

@Component({
  selector: 'app-lottery-card',
  templateUrl: './lottery-card.component.html',
  styleUrls: ['./lottery-card.component.css']
})
export class LotteryCardComponent {
  @Input() lottery: Lottery;
  public images: string[] = [];

  ngOnInit(): void {
    if (this.lottery?.image) {
      this.images = JSON.parse(this.lottery.image);
    }
  }
}
