import {Deserializable} from './deserializable.model';

export class User implements Deserializable {
    id: number = 0;
    username: string = '';
    password: string = '';
    email: string = '';
    status: number = 0;

    deserialize(input: any) {
        this.id = parseInt(input.id);
        this.username = input.username;
        this.password = input.password;
        this.email = input.email;
        this.status = parseInt(input.status);
        return this;
    }
}