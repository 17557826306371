import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { DatabaseService } from '../services/database.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard implements CanActivate {
  constructor(private databaseService: DatabaseService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.databaseService.getMaintenanceMode().pipe(map(maintenanceMode => {
      if (maintenanceMode) {
        this.router.navigate(['/maintenance']);
        return false;
      }
      return true;
    }));
  }
}
