<section class="bg-half-170 d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 align-items-center">
      <div class="col-lg-8 col-md-8">
        <div class="title-heading me-lg-4">
          <h1 class="heading mb-3">Llegó la hora de <span class="text-primary">Ganar</span> </h1>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <img src="assets/images/logo-circular.png" alt="" class="img-fluid">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<div *ngIf="openLotteries.length > 0" id="currentLotteries" class="pt-4">
  <section class="py-4 border-bottom border-top">
    <div class="container">
      <!-- <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center py-4"></app-clients-logo> -->
      <app-starred-lottery  *ngFor="let lottery of openLotteries" [lottery]="lottery"></app-starred-lottery>
    </div>
    <!--end container-->
  </section>
  <!--end section-->
</div>

<!-- Partners End -->

<!-- How It Work Start -->
<section class="section bg-light border-bottom">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Rifas realizadas</h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row align-items-center" *ngIf="closedLotteries.length > 0; else noClosedLotteriesTemplate">
      <app-lottery-card class="col-lg-3 col-md-6 col-12 " *ngFor="let lottery of closedLotteries" [lottery]="lottery"></app-lottery-card>
    </div>

    <ng-template #noClosedLotteriesTemplate>
      <div class="row align-items-center pb-4">
        <div class="col-12 text-center">
          <h5>No hay rifas finalizadas</h5>
        </div>
      </div>
    </ng-template>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- How It Work End -->

<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Pricing End -->

<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- FAQ n Contact End -->


<a href="https://wa.me/{{contactNumber}}" target="_blank" class="whatsapp-button">
  <img src="assets/images/whatsapp_icon.png" alt="Whatsapp">
</a>