import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/services/database.service';
import { EmailService } from 'src/app/services/email.service';
import { Lottery } from 'src/app/shared/models/lottery.model';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-lottery-detail',
  templateUrl: './lottery-detail.component.html',
  styleUrls: ['./lottery-detail.component.css']
})
export class LotteryDetailComponent implements OnInit{
  @Input() lottery: Lottery = null;
  public lotteryId: number = 0;
  public rewards: any[] = [];
  public ticketsAmount: number = 0;
  public paymentForm: FormGroup;
  public attachmentUrl: string = '';
  public stageIndex: number =  0;
  public stages: string[] = ['ticketsAmount','pricingDetails', 'paymentMethod', 'paymentForm','resultPage'];
  public settings: any[] = [];
  public bcvPrice: number = 0.00;
  public customStylesValidated: boolean = false;
  Menuoption = 'center';
  public loading: boolean = true;
  public requestResult: boolean = false;
  public mobilePaymentPhoneNumber = '';
  public mobilePaymentBank = '';
  public mobilePaymentIdNumber = '';
  public contactNumber = '';
  public images: string [] = [];

  constructor(
    private database: DatabaseService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private emailService: EmailService,
  ) {
    this.paymentForm = this.formBuilder.group({
      fullname: ['', Validators.required],
      id_number: ['', Validators.required],
      status: [0],
      datetime: [new Date()],
      reference: ['',[Validators.required]],
      attachment: [[],[Validators.required]],
      attachmentUrl: this.attachmentUrl,
      issuer_bank: ['',[Validators.required]],
      lottery_id: this.lottery?.id,
      tickets_id: [''],
      email: ['',[Validators.required, Validators.email]],
      amount: ['0.00', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      bcvPrice: this.bcvPrice,
      ticketsAmount: [1, [Validators.required, this.ticketsAmountValidator]],
    });

    if (!this.lottery) {
      this.route.params.subscribe(params => {
        const id = +params['lotteryId'];
  
        if (id) {
          this.database.getLotteryById(id).subscribe(lottery => {
            if (lottery) {
              this.lottery = lottery;
              this.lottery.id = id;
              this.lotteryId = id;
              this.rewards = JSON.parse(lottery.rewards);
              if (this.lottery.image.length > 0) {
                this.images = JSON.parse(this.lottery.image);
              }
              this.ticketsAmount = lottery.minimumTickets;
              this.cd.detectChanges();
            }
          });
        }
      });
    } else {
      this.lotteryId = this.lottery.id;
      this.rewards = JSON.parse(this.lottery.rewards);
      if (this.lottery.image.length > 0) {
        this.images = JSON.parse(this.lottery.image);
      }
      this.ticketsAmount = this.lottery.minimumTickets;
      this.cd.detectChanges();
    }
  }

  ngOnInit(): void {
    this.database.getAllSettings().subscribe((data:any) => {
      for (const item of data) {
        if (item.code == 'bcvPrice') {
        this.bcvPrice = item.value;
        }
        
        if (item.code == 'mobilePaymentPhoneNumber') {
        this.mobilePaymentPhoneNumber = item.value;
        }

        if (item.code == 'mobilePaymentBank') {
          this.mobilePaymentBank = item.value;
        }

        if (item.code == 'mobilePaymentIdNumber') {
          this.mobilePaymentIdNumber = item.value;
        }

        if (item.code == 'whatsapp') {
          this.contactNumber = item.value;
        }
      }
    });
  }

  private ticketsAmountValidator: ValidatorFn = (control) => {
    const value = control.value;
    // Check if the value is greater than 0.
    if (value > 0 && value != null) {
      control.setErrors({});
      return null; // Valid
    } else {
      control.setErrors({invalidTicketsAmount: true});
      return { invalidTicketsAmount: true }; // Invalid
    }
  }

  public decreaseTickets() {
    if (this.lottery.minimumTickets < this.ticketsAmount) {
      this.ticketsAmount--;
    }
  }

  public increaseTickets() {
    this.ticketsAmount++;
  }

  public advanceStage() {
    if (this.stageIndex < this.stages.length -1) {
      this.stageIndex++;
      this.cd.markForCheck();
    }
  }

  public backStage() {
    if (this.stageIndex > 0) {
      this.stageIndex--;
      this.cd.markForCheck();
    }
  }

  public submit() {
    this.stageIndex++;
    this.cd.markForCheck();
    this.customStylesValidated = true;
    this.paymentForm.get('lottery_id')?.setValue(this.lottery?.id);
    if (this.paymentForm.valid) {
      if (this.paymentForm.get('attachment')?.enabled && this.paymentForm.get('attachment')?.value) {
        const formData = new FormData();
        formData.append('file', this.paymentForm.get('attachment').value);
        // Upload image to server
        this.database.uploadAttachment(formData).subscribe(response => {
          if (response.success) {
            // Update the form with the image URL
            this.paymentForm.patchValue({
              attachmentUrl: response.url,
            });

            // Continue with form submission
            this.submitForm();
          } else {
            // Handle the case when the upload fails
            console.error("Failed to upload attachment");
          }
        });
        
      } else {
        this.submitForm();
      }
    } else {
      console.log('Form is invalid');
      this.cd.markForCheck();
    }
  }

  public async submitForm() {
    // const payment = new Payment().deserialize(this.paymentForm.value);
    // payment.id = this.paymentId;
    const paymentDate = moment.tz(this.paymentForm.get('datetime').value, 'America/Caracas').toDate();
    // const paymentDate = new Date(this.paymentForm.get('datetime').value);

    this.paymentForm.patchValue({
      datetime: paymentDate,
      bcvPrice: this.bcvPrice,
      amount: this.lottery.price * this.ticketsAmount,
      ticketsAmount: this.ticketsAmount,
    });
    console.log(this.paymentForm.value);

    //  const result:any = await this.database.createPayment(this.paymentForm.value);

     await this.database.createPayment(this.paymentForm.value).catch((error) => {
      console.error('Error creating payment', error);
      this.requestResult = false;
      this.loading = false;
     }).then((result) => {
      if (result.success) {
        if (result.success) {
          this.requestResult = true;
          this.loading = false;
          const lottery = this.lottery;
          const date = new Date(lottery!.datetime);
          const lotteryDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
          this.emailService.sendPaymentReceivedEmail(this.paymentForm.value.email,lotteryDate,result.id,lottery);
          this.emailService.sendPaymentReceivedAdmin(lottery,this.paymentForm.value);
          this.database.getLotteryById(this.lotteryId).subscribe(lottery => {
            if (lottery) {
              this.lottery = lottery;
              this.lottery.id = this.lotteryId;
              this.lotteryId = this.lotteryId;
              this.rewards = JSON.parse(lottery.rewards);
              this.ticketsAmount = lottery.minimumTickets;
              this.cd.markForCheck();
            }
          });
    
        } else {
          this.requestResult = false;
          this.loading = false;
        }
      }
     }).finally(() => {
        this.loading = false;
     });
      
    const fileInput = document.getElementById('attachment') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }

    // this.resetForm();

    // this.router.navigate(['/payments']);
  }

  public resetForm() {
    this.paymentForm.patchValue({
      ticketsAmount: this.lottery.minimumTickets,
      fullname: '',
      id_number: '',
      status: 0,
      datetime: new Date(),
      reference: '',
      attachment: [],
      attachmentUrl: this.attachmentUrl,
      issuer_bank: '',
      lottery_id: this.lottery?.id,
      tickets_id: '',
      email: '',
      amount: '0.00',
      phoneNumber: '',
      bcvPrice: this.bcvPrice,
    });
    this.stageIndex = 0;
    this.cd.markForCheck();
  }

  onFileChange(event: any) {
    this.paymentForm.get('attachment')?.setValue(event.target.files[0]);
  }

  onEmailDomainChangeSelectorChange() {
    if (this.paymentForm.value.emailDomainSelector == 'custom') {
      this.paymentForm.get('customDomain')?.setValidators([Validators.required]);
      this.paymentForm.get('customDomain')?.updateValueAndValidity();
    } else {
      this.paymentForm.get('customDomain')?.clearValidators();
      this.paymentForm.get('customDomain')?.updateValueAndValidity();
    }
  }

  onInput(event: any) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/\D/g, '');
  }

  get formattedText(): string {
    // Convert line breaks to HTML line breaks
    return this.lottery.description.replace(/\n/g, '<br>');
  }
}

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isValid = /\S+@\S+\.\S+/.test(control.value);
    return isValid ? null : { 'invalidEmail': { value: control.value } };
  };
}
