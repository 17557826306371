import {Deserializable} from './deserializable.model';

export class Lottery implements Deserializable{
    id: number = 0;
    name: string = '';
    status: boolean = true;
    description: string = '';
    image: string = '';
    rewards: string = '';
    price: number = 0;
    datetime: string = '';
    ticketsRange: number = 0;
    minimumTickets: number = 0;
    percentageBought: number = 0;
    boughtTickets: number = 0;

    deserialize(input: any) {

        this.id = parseInt(input.id);
        this.name = input.name;
        this.status = parseInt(input.status) == 1 ? true : false;
        this.description = input.description;
        this.image = input.image;
        this.rewards = input.rewards;
        this.price = input.price;
        this.datetime = input.datetime;
        this.ticketsRange = parseInt(input.ticketsRange);
        this.minimumTickets = input.minimumTickets;
        this.percentageBought = input.percentageBought;
        this.boughtTickets = input.boughtTickets;

        return this;
    }
}