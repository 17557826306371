<div class="mt-4 pt-2">
    <div class="card shop-list border-0 position-relative">
      <ul class="label list-unstyled mb-0">
        <li class="badge badge-link rounded-pill bg-success" *ngIf="lottery.status">Activa</li>
        <li class="badge badge-link rounded-pill bg-warning" *ngIf="!lottery.status">Cerrada</li>
      </ul>
      <div class="shop-image position-relative overflow-hidden rounded shadow">
        <a routerLink="/lottery/{{lottery.id}}">
            <img [src]="images.length > 0 ? images[0].replace('./','https://www.ganatetuauto.com/') : 'assets/images/no-image.png'" class="img-fluid"
            alt="">
        </a>

      </div>
      <div class="card-body content pt-4 p-2">
        <a routerLink="/lottery/{{lottery.id}}" class="text-dark product-name h4">{{lottery.name}}</a>
        <div class="d-flex justify-content-end mt-1">
          <a href="lottery/{{lottery.id}}"><button class="btn btn-primary">Ver</button></a>
        </div>
      </div>
    </div>
  </div>
