import { Component, Input, OnInit } from '@angular/core';
import { Lottery } from 'src/app/shared/models/lottery.model';

@Component({
  selector: 'app-starred-lottery',
  templateUrl: './starred-lottery.component.html',
  styleUrls: ['./starred-lottery.component.css']
})
export class StarredLotteryComponent implements OnInit {
  @Input() lottery:Lottery;
  public images: string [] = [];

 constructor() {
  // if (this.lottery.image) {
  //   this.images = JSON.parse(this.lottery.image);
  // }
 }

 ngOnInit(): void {
    if (this.lottery?.image) {
      this.images = JSON.parse(this.lottery.image);
    }
 }
}
