<div class="row mb-4" routerLink="/lottery/{{lottery.id}}" style="cursor: pointer;">
    <div class="col-md-4">
        <img [src]="images.length > 0 ? images[0].replace('./','https://www.ganatetuauto.com/') : 'assets/images/no-image.png'" alt="" class="img-fluid lottery-image">
    </div>
    <div class="col-md-6">
        <h1>{{lottery.name}}</h1>
        <h5 class="heading mb-3">¡Aprovecha y adquiere tus boletos antes de que se <span class="text-primary">agoten</span>!</h5>
        <a href="lottery/{{lottery.id}}"><button class="btn btn-primary">Compra tus boletos</button></a>
    </div>
</div>
<hr>