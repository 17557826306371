 <!-- Hero Start -->
 <section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
      <div class="row mt-5 justify-content-center">
          <div class="col-lg-12 text-center">
              <div class="pages-heading">
                  <h4 class="title mb-0"> Contacto </h4>
              </div>
          </div>  <!--end col-->
      </div><!--end row-->
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- About Start -->
<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="position-relative">
          <img src="assets/images/logo-circular.png" class="rounded img-fluid mx-auto d-block" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0 text-center">
        <div class="section-title ms-lg-4">
          <h4 class="title mb-4">Whatsapp</h4>
          <a href="https://wa.me/{{contactNumber}}" target="_blank" class="btn btn-success mt-3">Escríbenos haciendo clic aquí</a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- About End -->
<!-- Modal Content End -->
<a href="https://wa.me/{{contactNumber}}" target="_blank" class="whatsapp-button">
  <img src="assets/images/whatsapp_icon.png" alt="Whatsapp">
</a>