import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DatabaseService } from 'src/app/services/database.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent {
  constructor(
    private databaseService: DatabaseService,
    private router: Router,
  ) {
    this.databaseService.getMaintenanceMode().subscribe((maintenanceMode) => {
      if (!maintenanceMode) {
        this.router.navigate(['/']);
      }
    });
  }
}
