<div class="container vh-100 d-flex justify-content-center align-items-center">
    <div class="text-center">
        <a routerLink="/">
            <img src="assets/images/logo-circular.png" alt="Logo" class="img-fluid col-md-4">
        </a>
        <div class="maintenance-message">
            <h1><a href="/">www.ganatetuauto.com</a></h1>
            <h2>¡Estamos en mantenimiento!</h2>
            <h3>¡Volveremos pronto!</h3>
        </div>
    </div>
</div>