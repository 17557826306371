import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DatabaseService } from 'src/app/services/database.service';
import { Lottery } from 'src/app/shared/models/lottery.model';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})

/**
 * Index Component
 */
export class IndexComponent implements OnInit {
  public lotteries: Lottery[] = [];
  public openLotteries: Lottery[] = [];
  public closedLotteries: Lottery[] = [];
  public starredLottery: Lottery | undefined;
  public contactNumber: string = '';
  developmentForm!: UntypedFormGroup;
  submitted = false;
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;

  /**
   * Partners slider
   */
  customOptions: OwlOptions = {
    // loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    // autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: false
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private database: DatabaseService,
    ) { 
      this.database.getAllLotteries().subscribe((data: any) => {
        this.lotteries = data;
        this.openLotteries = this.lotteries.filter(lottery => lottery.status === true);
        this.closedLotteries = this.lotteries.filter(lottery => lottery.status === false);
        this.lotteries.sort((a,b) => new Date(b.datetime).setUTCMilliseconds > new Date(a.datetime).setUTCMilliseconds ? 1 : -1);
        });
    }

  ngOnInit(): void {
    /**
     * Form Validatyion
     */
    this.developmentForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    this.database.getAllSettings().subscribe((data:any) => {
      for (const item of data) {
        if (item.code == 'whatsapp') {
          this.contactNumber = item.value;
        }
      }
    });
  }

  // convenience getter for easy access to form fields
  get form() { return this.developmentForm.controls; }

  /**
   * On submit form
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.developmentForm.invalid) {
      return;
    }
  }

}
