import {Deserializable} from './deserializable.model';

export class Payment implements Deserializable{
    id: number = 0;
    id_number: string = '';
    fullname: string = '';
    status: number = 0;
    datetime: string = '';
    reference: string = '';
    attachment: string = '';
    issuer_bank: string = '';
    lottery_id: number = 0;
    tickets_id: string = '';
    email: string = '';
    amount: number = 0;

    deserialize(input: any) {

        this.id = parseInt(input.id);
        this.id_number = input.id_number;
        this.fullname = input.fullname;
        this.status = parseInt(input.status);
        this.datetime = input.datetime;
        this.reference = input.reference;
        this.attachment = input.attachment;
        this.issuer_bank = input.issuer_bank;
        this.lottery_id = parseInt(input.lottery_id);
        this.tickets_id = JSON.parse(input.tickets_id);
        this.email = input.email;
        this.amount = input.amount;

        return this;
    }
}