<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0"> Términos y Condiciones </h4>
        </div>
      </div> <!--end col-->
    </div><!--end row-->
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow border-0 rounded">
          <div class="card-body">
            <ol style="text-align: justify;">
              <li>
                <b>Gánate tu Auto</b> Proporciona una página web automatizada para organizar concursos, al
                participar aceptas cumplir con las reglas y términos específicos establecidos.
              </li><br>
              <li>
                Pueden concursar únicamente personas mayores de 18 años, Venezolanos o Extranjeros
                residentes en Venezuela. En caso de participar desde otro país debe tener alguna
                persona autorizada en Venezuela para hacerle entrega del premio.
              </li><br>
              <li>
                Los premios deben ser retirados personalmente en el lugar indicado por <b>Gánate tu Auto</b>
                en cada concurso realizado, solo se entregara personalmente en la dirección indicada
                por el ganador de premio mayor o (Primer premio).
              </li><br>
              <li>
                <b>Gánate tu Auto</b> No se hace responsable por los participantes NO ganadores dentro de
                los concursos realizados. No se ofrecerán compensaciones, ni se realizaran reembolsos
                en caso de no ganar.
              </li><br>
              <li>
                Al participar los ganadores aceptan que <b>Gánate tu Auto</b> difunda en todas sus redes
                sociales fotos y videos con la presencia de los ganadores junto con el premio luego de
                haber sido entregado.
              </li><br>
            </ol>
            
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="https://wa.me/{{contactNumber}}" target="_blank" class="whatsapp-button">
  <img src="assets/images/whatsapp_icon.png" alt="Whatsapp">
</a>
<!-- Back to top -->